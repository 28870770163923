import { RosterRow } from './RosterRowContent.jsx';

export default function Rows(props) {
  const rosters = props.data.viewer.rosterSet.edges;
  if (rosters.length === 0) return;
  const students = rosters[0].node.rostermembershipSet.edges;

  return (
    <>
      {students.map((item, index) => (
        <RosterRow key={item.node.user.id} student={item.node.user} />
      ))}
    </>
  );
}
