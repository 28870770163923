import RequestCancelBtn from './RequestCancelBtn';
import getColorStatus from '../status';

export function RequestRow(props) {
  function getName(props) {
    return `${props.request.student.firstName} ${props.request.student.lastName}`;
  }

  function getHomeroomTeacherName(props) {
    const teacher =
      props.request.student.rosterSet.edges[0].node.rostermembershipSet.edges[0]
        .node.user;
    return `${teacher.firstName} ${teacher.lastName}`;
  }

  function getStatus(props) {
    const status = props.request.status[0];
    return getColorStatus(status);
  }

  function getCategory(props) {
    const category = props.request.category;
    return category.name;
  }

  return (
    <tr key={'tr' + props.request.id}>
      <td>{getName(props)}</td>
      <td>{getHomeroomTeacherName(props)}</td>
      <td>{getStatus(props)}</td>
      <td>{getCategory(props)}</td>
      <td>
        <RequestCancelBtn request={props.request} />
      </td>
    </tr>
  );
}
