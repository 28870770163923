import { useState } from 'react';
import TestModal from './TestModal';
import ReactModal from 'react-modal';

ReactModal.setAppElement('#root');

export default function RequestButton() {
  const [modalIsOpen, setIsOpen] = useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <div>
      <button id="request" onClick={openModal}>
        Request Student
      </button>
      <TestModal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Test Modal"
      />
    </div>
  );
}
