import { gql } from '@apollo/client';

export const LOGIN_USER = gql`
  mutation loginUser($username: String!, $password: String!) {
    tokenAuth(input: { username: $username, password: $password }) {
      token
      payload
      refreshExpiresIn
      clientMutationId
      payload
    }
  }
`;
