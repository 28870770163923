export default function TopText() {
  return (
    <div id="toptext">
      <span>
        <span id="school-name">Centralia High School</span> | Panther Academic
        Support System
      </span>
      <br />
      <span>PASS Teacher Portal</span>
    </div>
  );
}
