export default function OverflowCheck({ disabled, buttonNum }) {
  const buttonID = 'overflowCheck' + buttonNum;
  let displayLabel = false;
  return (
    <>
      <input type="checkbox" id={buttonID} disabled={disabled} />
      <label htmlFor={buttonID}>{displayLabel && 'Overflow?'}</label>
    </>
  );
}
