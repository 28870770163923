import TopButton from './TopButton.jsx';
import { useNavigate } from 'react-router-dom';

export default function TopButtons({ handleClick }) {
  let navigate = useNavigate();

  return (
    <ul id="topbuttons">
      <TopButton onClick={() => navigate('/')} name="Roster" />
      <TopButton onClick={() => navigate('/thisweek')} name="This Week" />
      <TopButton onClick={() => navigate('/nextweek')} name="Next Week" />
    </ul>
  );
}
