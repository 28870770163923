import { DateTime } from "luxon";
import { GET_REQUESTS_FOR_STUDENT } from '../../graphql/requestsforstudents.js';
import { DELETE_REQUEST } from "../../graphql/requests.js";
import {useQuery, useMutation} from '@apollo/client';
import toast from 'react-hot-toast';
import getColorStatus from "../status.jsx";

export default function PassInfo(props) {
    const { data, loading, error } = useQuery(GET_REQUESTS_FOR_STUDENT);
    const toast_errors = error => toast.error(error.message);
    const [deleteRequest, { error: createReqErr, data: createReqData }] = 
    useMutation(DELETE_REQUEST, {
        refetchQueries: [GET_REQUESTS_FOR_STUDENT, 'getRequestsForStudent'],
        errorPolicy: 'none',
        awaitRefetchQueries: true
    });

    if(loading){
        return;
    }

    const requests = props.manage ? data.viewer.myRequests : data.viewer.requestSet

    function pressDelete(reqID) {
      toast.loading('Deleting request...');
      deleteRequest({
        variables: {
            requestId: reqID,
            soft: true
        }
      }) || toast.error();
      toast.remove();
      if (createReqErr) {
        createReqErr.graphQLErrors.map(toast_errors);
        //createReqErr.networkError.map(toast_errors);
      } else if (createReqData) {
        toast.success('Deleted request');
      }
    }

    return(
        <table className="roster">
            <th colSpan={props.manage ? 5 : 4}>
                <div id="top-roster">
                    <div id="top-roster-title">{props.manage ? "My Requests" : "PASS Information"}</div>
                </div>
            </th>
            <tr>
            <th>Teacher</th>
            <th>Date</th>
            <th>Reason</th>
            <th>Status</th>
            {props.manage && <th>Options</th>}
            </tr>
            {requests.edges.map((item) => (
                <tr key={item.node.id}>
                    <td>{item.node.destination.primaryTeacher.firstName} {item.node.destination.primaryTeacher.lastName}</td>
                    <td>{item.node.dateRequested}</td>
                    <td>{item.node.category.name}</td>
		    <td>{getColorStatus(item.node.status[0])}</td>
                    {props.manage && <td><button onClick={() => pressDelete(item.node.id)}>Delete</button></td>} 
                </tr>
            ))}
        </table>
    );
}
