import { DateTime } from 'luxon';
import RequestButton from '../RequestButton.jsx';
import RequestRows from './RequestRows.jsx';

export default function RequestTable(props) {
  const today = DateTime.now();

  return (
    <table className="roster">
      <thead>
        <tr id="roster-head">
          <th colSpan={5}>
            <div id="top-roster">
              <div id="top-roster-title">
                My requests for {DateTime.now().toISODate()}
              </div>
              <div id="top-roster-button">
                <RequestButton />{' '}
              </div>
            </div>
          </th>
        </tr>
        <tr>
          <th>Student</th>
          <th>Homeroom</th>
          <th>Status</th>
          <th>Request Category</th>
          <th>Manage</th>
        </tr>
      </thead>
      <tbody>
        <RequestRows today={today} data={props.data} />
      </tbody>
    </table>
  );
}
