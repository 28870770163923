import PassInfo from "./PassInfo.jsx";
import RequestTeacher from "./RequestTeacher.jsx"
import StudentTopBar from "./StudentPortalTopBar.jsx";

export default function StudentPortal() {
    //const {loading, error, data } = useQuery(GET_ROSTER, {variables: {dateRequested: today.toISODate()}, pollInterval: 500});
    //if (loading) return "Loading...";
    return(
      <div>
        <StudentTopBar manage={false} />
        <div className="main-page">
          <PassInfo manage={false}/>
          <div className="main-spacer" />
          <PassInfo manage={true} />
          <div className="main-spacer" />
          <RequestTeacher />    
        </div>
      </div>
    );
}