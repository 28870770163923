import IncomingRow from './IncomingRow';

export default function IncomingReq({ requests }) {
  return (
    <table className="roster">
      <thead>
        <tr id="roster-head">
          <th colSpan={6}>
            <div id="top-roster">
              <div id="top-roster-title">Incoming Requests</div>
            </div>
          </th>
        </tr>
        <tr>
          <th>Student</th>
          <th>Date</th>
          <th>Homeroom</th>
          <th>Status</th>
          <th>Request Category</th>
          <th>Manage</th>
        </tr>
      </thead>
      <tbody>
        {requests.map((request, index) => (
          <IncomingRow key={`request-${index}`} studentReq={request} />
        ))}
      </tbody>
    </table>
  );
}
