import React, { useState } from 'react';
import ForgotPasswordButton from './ForgotPassword';
import { LOGIN_USER } from '../graphql/login';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/authContext';
import { useMutation } from '@apollo/react-hooks';
import { useContext } from 'react';

export default function LoginBox() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const [errors, setErrors] = useState('');
  const context = useContext(AuthContext);
  let navigate = useNavigate();

  const [loginUser] = useMutation(LOGIN_USER, {
    update(proxy, { data: { tokenAuth: userData } }) {
      context.login(userData);
      navigate('/');
    },
    onError({ graphQLErrors }) {
      setErrors(graphQLErrors);
    },
    variables: { username: username, password: password }
  });

  // Username box functionality
  function getUsername() {
    return username;
  }

  const handleUsernameInput = event => {
    setUsername(event.target.value);
  };

  // Password box functionality
  const handlePasswordInput = event => {
    setPassword(event.target.value);
  };

  function getPassword() {
    return password;
  }

  // Login button functionality. Do not allow entry if username or password is empty
  function handleLogin(username = '', password = '') {
    if (username === '' || password === '') {
      console.log('Uh oh, try again');
    } else {
      loginUser();
    }
  }

  return (
    <div>
      Username:
      <div>
        <input
          id="UsernameText"
          onChange={handleUsernameInput}
          placeholder="Enter your username"
        />
      </div>
      Password
      <div>
        <input
          id="PasswordText"
          onChange={handlePasswordInput}
          placeholder="Enter your password"
          type="password"
        />
      </div>
      <ForgotPasswordButton />
      <br />
      <div id="login">
        <button onClick={() => handleLogin(getUsername(), getPassword())}>
          Login
        </button>
      </div>
      {errors &&
        errors.map(function (error) {
          return <p severity="error">{error.message}</p>;
        })}
    </div>
  );
}
