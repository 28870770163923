import { useMutation } from '@apollo/client';
import {
  GET_ROSTER,
  ADD_ABSENCE,
  DELETE_ABSENCE
} from '../../graphql/roster.js';

export default function AbsenceCheck(props) {
  const [addFunction] = useMutation(ADD_ABSENCE, {
    variables: { studentId: props.student.id },
    refetchQueries: [GET_ROSTER]
  });
  const [delFunction] = useMutation(DELETE_ABSENCE, {
    refetchQueries: [GET_ROSTER]
  });
  function handleClick() {
    if (props.student.absences.edges.length > 0) {
      const absenceId = props.student.absences.edges[0].node.id;
      delFunction({ variables: { absenceId: absenceId } });
    } else {
      addFunction();
    }
  }
  return (
    <>
      <input
        type="checkbox"
        checked={props.student.absences.edges.length > 0}
        onChange={handleClick}
      />
    </>
  );
}
