import RequestApprovedBtn from './RequestApproveBtn';
import RequestDenyBtn from './RequestDenyBtn';
import getColorStatus from '../../status';

export default function IncomingRow({ studentReq }) {
  return (
    <tr key={'tr' + studentReq.firstName}>
      <td>{studentReq.name}</td>
      <td>{studentReq.date}</td>
      <td>{studentReq.homeroom}</td>
      <td>{getColorStatus(studentReq.status)}</td>
      <td>{studentReq.category}</td>
      <td>
        <RequestApprovedBtn requestID={studentReq.id} /> <RequestDenyBtn requestID={studentReq.id} />{' '}
      </td>
    </tr>
  );
}
