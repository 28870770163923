import { gql } from '@apollo/client';

export const GET_REQUESTS_FOR_STUDENT = gql`
query getRequestsForStudent {
  viewer {
    requestSet {
      edges {
        node {
          dateRequested
          category {
            name
          }
          status
          destination {
            primaryTeacher {
              firstName
              lastName
            }
          }
          id
        }
      }
    }
    myRequests {
      edges {
        node {
          dateRequested
          category {
            name
          }
          status
          destination {
            primaryTeacher {
              firstName
              lastName
            }
          }
          id
        }
      }
    }
  }
}
`;
