import {useQuery} from '@apollo/client';
import { DateTime } from "luxon";
import RosterTable from "./RosterTable.jsx";
import { GET_ROSTER } from "../../graphql/roster.js";
import RequestTable from "./RequestTable.jsx";
import IncomingReq from "./incoming/IncomingReq.jsx";
import TopBar from "../TopBar.jsx";

export default function Roster() {
    const today = DateTime.now();
    const { loading, data } = useQuery(GET_ROSTER, {variables: {dateRequested: today.toISODate()}, pollInterval: 50000});
    if (loading) return "Loading...";
    let incomingRequests = data.viewer.rosterSet.edges[0].node.incomingRequests; 

    let requests = [];
    for (let i = 0; i < incomingRequests.length; i++) {
      let reqObj = {
        id: incomingRequests[i].id, 
        name: `${incomingRequests[i].requestor.firstName} ${incomingRequests[i].requestor.lastName}`, 
        date: incomingRequests[i].dateRequested, 
        homeroom: "N/A", 
        status: incomingRequests[i].status[0], 
        category: incomingRequests[i].category.name
      };
      requests.push(reqObj);
    }

    return(
      <div>
        <TopBar />
        <div className="main-page">
          {(incomingRequests.length != 0) && <div><IncomingReq requests={requests} /> <div className="main-spacer" /></div>}
          <RequestTable data={data} />
          <div className="main-spacer" />
          <RosterTable data={data} />    
        </div>
      </div>
    );
}

