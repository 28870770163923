import CalendarRow from './CalendarRow.jsx';
import OverflowCheck from '../OverflowCheck.jsx';
import RequestButton from '../RequestButton.jsx';
import { DateTime } from 'luxon';


export default function Calendar({ weekString, day, data, weekBeginning }) {
  let studentList = [];
  for (let i = 0; i < data.viewer.rosterSet.edges[0].node.rostermembershipSet.edges.length; i++) {
    const student = data.viewer.rosterSet.edges[0].node.rostermembershipSet.edges[i];

    let studentObject = {
      id: student.node.user.id, 
      name: `${student.node.user.firstName} ${student.node.user.lastName}`, 
      requests: [], 
      absences: []
    };

    for (let x = 0; x < student.node.user.requestSet.edges.length; x++) {
      if (student.node.user.requestSet.edges[x].node.status[0] == "PRC") {
        studentObject.requests.push({date: student.node.user.requestSet.edges[x].node.dateRequested, dest: student.node.user.requestSet.edges[x].node.destination.primaryTeacher.lastName});
    
      }
    }

    for (let x = 0; x < student.node.user.absences.edges.length; x++) {
      studentObject.absences.push(student.node.user.absences.edges[x].node.date);
    }

    studentList.push(studentObject);
  }

  // Create a Week for each student and populate it with proper stuff
  let StudentWeeks = [];
  // For each student, access their requests.
  for (let i = 0; i < studentList.length; i++) {
    // For each request, create an entry for the specific day
    let WeekObj = {
      name: studentList[i].name, 
      days: [null, null, null, null, null],
    };

    for (let x = 0; x < studentList[i].requests.length; x++) {
      const requestDay = DateTime.fromISO(studentList[i].requests[x].date);
      WeekObj.days[requestDay.weekday - 1] = <span><span id="bold">R</span> - {studentList[i].requests[x].dest}</span>
    }

    for (let x = 0; x < studentList[i].absences.length; x++) {
      const absenceDay = DateTime.fromISO(studentList[i].absences[x]).toObject();
      let dayNum = absenceDay.day - weekBeginning.day;
      WeekObj.days[dayNum] = <span>Absent</span>
    }

    StudentWeeks.push(WeekObj);
  }

  function getDay(week, index) {
    const otherday = index + 1;
    let weekID = null; 
    if (day >= otherday) {
      weekID = 'disabled';
    }
    return (<td id={weekID}>{week}</td>);
  }
  
  let body = StudentWeeks.map((student) => (
    <tr key={student.name}>
      <td>{student.name}</td>
      {student.days.map((week, index) => (getDay(week, index)))}
    </tr>
  )); 


  return (
    <div>
      <table className="calendar">
        <thead>
          <tr id="calendar-head">
            <th colSpan={6}>
              <div id="top-calendar">
                <div id="top-calendar-title">
                  Manage Requests for Week of {weekString}
                </div>
                <div id="top-calendar-button">
                  <RequestButton />{' '}
                </div>
              </div>
            </th>
          </tr>
          <tr>
            <th>Student Name</th>
            <th id={day >= 1 ? 'disabled' : null}>Monday</th>
            <th id={day >= 2 ? 'disabled' : null}>Tuesday</th>
            <th id={day >= 3 ? 'disabled' : null}>Wednesday</th>
            <th id={day >= 4 ? 'disabled' : null}>Thursday</th>
            <th id={day >= 5 ? 'disabled' : null}>Friday</th>
          </tr>
        </thead>
        <tbody>
          {body}
        </tbody>
      </table>
    </div>
  );
}
