import { useState, useEffect } from 'react';
import { useMutation, useLazyQuery } from '@apollo/client';
import { CREATE_REQUEST, CHECK_EXISTS } from '../graphql/requests';
import { GET_ROSTER } from '../graphql/roster';
import { DateTime } from 'luxon';
import toast from 'react-hot-toast';

export default function ReqRow({ student, categories, user, roster }) {
  const min_date = DateTime.now().toISO().split('T')[0];
  const [reasonText, setReason] = useState('Please Select an Option');
  const [choseReason, setReasonId] = useState('');
  const [choseDate, setDate] = useState(min_date);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const toast_errors = error => toast.error(error.message);

  const [createRequest, { error: createReqErr, data: createReqData }] =
    useMutation(CREATE_REQUEST, {
      refetchQueries: [GET_ROSTER, 'getRoster'],
      errorPolicy: 'none',
      awaitRefetchQueries: true
    });

  function handleDateChange(event) {
    setDate(event.target.value);
  }

  function handleReasonChange(reason) {
    setReason(reason);
    const selectedCategory = categories.find(
      category => category.node.name === reason
    );
    setReasonId((selectedCategory && selectedCategory.node.id) || '');
  }

  function requestStudent(student, choseDate, choseReason, user) {
    !buttonDisabled && setButtonDisabled(true);
    setReasonId('');
    setReason('Please Select an Option');
    setDate('');
    toast.loading('Creating request...');
    createRequest({
      variables: {
        studentId: student.id,
        requestorId: user.id,
        destinationId: roster.id,
        categoryId: choseReason,
        dateRequested: choseDate
      }
    }) || toast.error();
    toast.remove();
    if (createReqErr) {
      createReqErr.graphQLErrors.map(toast_errors);
      createReqErr.networkError.map(toast_errors);
    } else if (createReqData) {
      toast.success('Created request');
    }
  }

  const [doQuery, { error, data }] = useLazyQuery(CHECK_EXISTS);

  if (error) {
    error.graphQLErrors.map(toast_errors);
  }

  useEffect(() => {
    if (choseReason !== '' && choseDate !== '') {
      doQuery({
        variables: {
          dateRequested: choseDate,
          studentID: student.id,
          destinationID: roster.id
        }
      });
    }
  }, [reasonText, choseDate, choseReason, doQuery, student.id, roster.id]);

  useEffect(() => {
    if (data) {
      const { edges } = data.allRequests;
      if (edges.length === 0) {
        setButtonDisabled(false);
      } else {
        setButtonDisabled(true);
      }
    }
  }, [data, buttonDisabled]);

  return (
    <tbody>
      <tr>
        <td id="name">{student.firstName + ' ' + student.lastName + ' '}</td>
        <td>
          <input
            onChange={handleDateChange}
            type="date"
            min={min_date}
            value={choseDate}
            required
          />{' '}
        </td>
        <td>
          <select
            id="reasons"
            value={reasonText}
            onChange={event => handleReasonChange(event.target.value)}
          >
            <option value={'Please Select an Option'}>
              Please Select an Option
            </option>
            {categories.map(category => (
              <option key={category.node.id} value={category.node.name}>
                {category.node.name}
              </option>
            ))}
          </select>
        </td>
        <td id="req-button">
          <button
            disabled={buttonDisabled || !choseReason || !choseDate}
            onClick={() =>
              requestStudent(student, choseDate, choseReason, user)
            }
          >
            Request
          </button>
        </td>
      </tr>
    </tbody>
  );
}
