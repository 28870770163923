import { gql } from '@apollo/client';

export const GET_STUDENT_PORTAL_INFO = gql`
query getCategories {
  allUsers(group: "R3JvdXBOb2RlOjE=") {
    edges {
      node {
        id
        firstName
        lastName
        rosterSet {
          edges {
            node {
              id
            }
          }
        }
      }
    }
  }
  allCategories {
    edges {
      node {
        id
        name
      }
    }
  }
  viewer {
    id
  }
}
`;