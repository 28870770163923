import { DELETE_REQUEST } from '../../graphql/roster.js';
import { useMutation } from '@apollo/client';
import { useState } from 'react';
import toast from 'react-hot-toast';

export default function RequestCancelBtn(props) {
  const [delFunction] = useMutation(DELETE_REQUEST);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const { id } = props.request;
  function delRequest(event) {
    setButtonDisabled(false);

    delFunction({
      variables: {
        requestId: props.request.id
      },
      update(cache) {
        const normalizedId = cache.identify({ id, __typename: 'RequestNode' });
        cache.evict({ id: normalizedId });
        cache.gc();
      }
    })
      .then(() => {
        toast.success('Canceled request');
        setButtonDisabled(true);
      })
      .catch(error => {
        setButtonDisabled(true);
        toast.error('Error: ', error);
      });
  }

  return (
    <button onClick={e => delRequest(e)} disabled={buttonDisabled}>
      Cancel
    </button>
  );
}
