import logo from '../assets/centralialogo.png';
import TopButtons from './TopButtons.jsx';
import TopText from './TopText.jsx';
import LogoutButton from './LogoutButton.jsx';
import { Toaster } from 'react-hot-toast';

export default function TopBar({ buttonFunction }) {
  return (
    <div id="topbar">
      <Toaster />
      <div id="topbarTop">
        <img id="toplogo" alt="Centralia Public Schools Logo" src={logo} />
        <TopText />
      </div>
      <div id="overallbuttons">
        <TopButtons handleClick={buttonFunction} />
        <LogoutButton />
      </div>
    </div>
  );
}
