import CalendarRow from './calendar/CalendarRow.jsx';
import { DateTime } from 'luxon';
import getColorStatus from './status.jsx';

export default function RequestCalendar({ weekString, day, data, weekBeginning }) {
  let studentList = []
  for (let i=0; i<data.viewer.myRequests.edges.length; i++) {
    const student = data.viewer.myRequests.edges[i]; 

    let student_exists = false; 
    let student_index = -1; 
    for (let x=0; x< studentList.length; x++){ 
      if (studentList[x].name === `${student.node.student.firstName} ${student.node.student.lastName}`) {
        student_exists = true;
        student_index = x;
      }
    }

    if (student_exists === false) {
      const StudentObj = {
        id: student.node.id, 
        name: `${student.node.student.firstName} ${student.node.student.lastName}`,
        requests: []
      };
      // add the initial request 
      StudentObj.requests.push({date: student.node.dateRequested, status: student.node.status[0]});
      studentList.push(StudentObj);
    } else {
      // Add subsequent requests to the existing student object
      studentList[student_index].requests.push({date: student.node.dateRequested, status: student.node.status[0]});
    }
  
    
  }

  // Create a Week for each student and populate it with proper stuff
  let StudentWeeks = [];
  for (let i = 0; i < studentList.length; i++) {
    // For each request, create an entry for the specific day
    let WeekObj = {
      name: studentList[i].name, 
      days: [null, null, null, null, null],
    };

    for (let x = 0; x < studentList[i].requests.length; x++) {
      const requestDay = DateTime.fromISO(studentList[i].requests[x].date)
      // const weekBeginning = DateTime.now().startOf('week').toObject();
      WeekObj.days[requestDay.weekday - 1] = <span>{getColorStatus(studentList[i].requests[x].status)}</span>
    }
    StudentWeeks.push(WeekObj);
  }

  function getDay(week, index) {
    const otherday = index + 1;
    let weekID = null; 
    if (day >= otherday) {
      weekID = 'disabled';
    }
    return (<td id={weekID}>{week}</td>);
  }
  
  let body = StudentWeeks.map((student) => (
    <tr key={student.name}>
      <td>{student.name}</td>
      {student.days.map((week, index) => (getDay(week, index)))}
    </tr>
  )); 

  return (
    <div>
      <table className="calendar">
        <thead>
          <tr id="calendar-head">
            <th colSpan={6}>
              <div id="top-calendar">
                <div id="top-calendar-title">
                  Your Requests for Week of {weekString}
                </div>
              </div>
            </th>
          </tr>
          <tr>
            <th>Student Name</th>
            <th id={day >= 1 ? 'disabled' : null}>Monday</th>
            <th id={day >= 2 ? 'disabled' : null}>Tuesday</th>
            <th id={day >= 3 ? 'disabled' : null}>Wednesday</th>
            <th id={day >= 4 ? 'disabled' : null}>Thursday</th>
            <th id={day >= 5 ? 'disabled' : null}>Friday</th>
          </tr>
        </thead>
        <tbody>
          {body}
        </tbody>
      </table>
    </div>
  );
}
