import { DateTime } from "luxon";
import Rows from "./RosterRows.jsx";

export default function RosterTable(props) {
  const today = DateTime.now();

  return (
    <table className="roster">
      <thead>
        <tr id="roster-head">
          <th colSpan={5}>
            <div id="top-roster">
              <div id="top-roster-title">Homeroom roster for {DateTime.now().toISODate()}</div>
            </div>
          </th>
        </tr>
        <tr>
          <th>Student</th>
          <th>Status</th>
          <th>Outgoing To</th>
          <th>Absent</th>
          <th>Overflow</th>
        </tr>
      </thead>
      <tbody>
        <Rows today={today} data={props.data} />
      </tbody>
    </table>
  );
}