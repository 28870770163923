import Calendar from './calendar/Calendar.jsx';
import { DateTime } from 'luxon';
import RequestCalendar from './RequestCalendar.jsx';
import TopBar from './TopBar.jsx';
import {useQuery} from '@apollo/client';
import { GET_ROSTER_WEEK } from "../graphql/roster.js";

export default function ThisWeek() {
  const today = DateTime.now();
  const todayObj = today.toObject();
  const weekBeginning = today.startOf('week').toObject();
  const week_str =
    weekBeginning.month + '-' + weekBeginning.day + '-' + weekBeginning.year;
  const currentDay = todayObj.day - weekBeginning.day;

  const { loading, data } = useQuery(GET_ROSTER_WEEK, {variables: {dateRequested_Gte: today.startOf('week').toISODate(), dateRequested_Lte: today.endOf('week').toISODate()}, pollInterval: 50000});

  if (loading) {
    return "loading";
  }

  return (
    <div>
      <TopBar />
      <div className="main-page">
        <Calendar weekString={week_str} day={currentDay} data={data} weekBeginning={weekBeginning} />
        <div className="main-spacer"></div>
        <RequestCalendar weekString={week_str} day={currentDay} data={data} weekBeginning={weekBeginning} />
      </div>
    </div>
  );
}
