import logo from './assets/centralialogo.png';
import LoginBox from './login/LoginBox';

function Login() {
  return (
    <div>
      <div id="topbar">
        <img id="toplogo" alt="Centralia Public Schools Logo" src={logo} />
      </div>
      <div id="loginBox">
        <div>
          <LoginBox />
        </div>
      </div>
    </div>
  );
}

export default Login;
