import { gql } from '@apollo/client';

export const GET_ROSTER = gql`
  query getRoster($dateRequested: Date) {
    viewer {
      email
      firstName
      id
      lastName
      rosterSet {
        edges {
          node {
            id
            incomingRequests {
              id
              destination {
                roomNumber
                subject {
                  name
                }
              }
              requestor {
                firstName
                lastName
              }
              category {
                name
              }
              status
              dateRequested
            }
            rostermembershipSet(role: STU) {
              edges {
                node {
                  role
                  user {
                    firstName
                    lastName
                    id
                    absences(date: $dateRequested) {
                      edges {
                        node {
                          date
                          id
                        }
                      }
                    }
                    requestSet(dateRequested: $dateRequested) {
                      edges {
                        node {
                          id
                          destination {
                            roomNumber
                            subject {
                              name
                            }
                          }
                          requestor {
                            firstName
                            lastName
                          }
                          category {
                            name
                          }
                          status
                          dateRequested
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      myRequests(dateRequested: $dateRequested) {
        edges {
          node {
            id
            student {
              firstName
              lastName
              rosterSet {
                edges {
                  node {
                    rostermembershipSet(role: OWN) {
                      edges {
                        node {
                          user {
                            firstName
                            lastName
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            category {
              name
            }
            status
          }
        }
      }
    }
  }
`;

export const GET_ROSTER_WEEK = gql`
  query getRoster($dateRequested_Gte: Date, $dateRequested_Lte: Date) {
    viewer {
      email
      firstName
      id
      lastName
      rosterSet {
        edges {
          node {
            id
            rostermembershipSet(role: STU) {
              edges {
                node {
                  role
                  user {
                    firstName
                    lastName
                    id
                    absences(date_Gte: $dateRequested_Gte, date_Lte: $dateRequested_Lte) {
                      edges {
                        node {
                          date
                          id
                        }
                      }
                    }
                    requestSet(dateRequested_Gte: $dateRequested_Gte, dateRequested_Lte: $dateRequested_Lte) {
                      edges {
                        node {
                          id
                          destination {
                            roomNumber
                            primaryTeacher {
                              id 
                              firstName 
                              lastName
                            }
                            subject {
                              name
                            }
                          }
                          requestor {
                            firstName
                            lastName
                          }
                          category {
                            name
                          }
                          status
                          dateRequested
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      myRequests(dateRequested_Gte: $dateRequested_Gte, dateRequested_Lte: $dateRequested_Lte) {
        edges {
          node {
            id
            student {
              firstName
              lastName
              rosterSet {
                edges {
                  node {
                    rostermembershipSet(role: OWN) {
                      edges {
                        node {
                          user {
                            firstName
                            lastName
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            category {
              name
            }
            status 
            dateRequested
          }
        }
      }
    }
  }
`;

export const ADD_ABSENCE = gql`
  mutation CreateAbsence($studentId: ID!) {
    createAbsence(input: { studentId: $studentId }) {
      absence {
        absent
        date
      }
    }
  }
`;

export const DELETE_ABSENCE = gql`
  mutation DeleteAbsence($absenceId: ID!) {
    deleteAbsence(input: { absenceId: $absenceId }) {
      absence {
        absent
        date
      }
    }
  }
`;

export const DELETE_REQUEST = gql`
  mutation DeleteRequest($requestId: ID!) {
    deleteRequest(input: { requestId: $requestId, soft: false }) {
      clientMutationId
    }
  }
`;
