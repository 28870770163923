import logo from "../../assets/centralialogo.png"
import StudentTopText from "./StudentTopText.jsx"
import LogoutButton from "../LogoutButton.jsx";

export default function StudentTopBar() {
    return (
        <div id="topbar">
            <div id="topbarTop">
                <img id="toplogo" alt="Centralia Public Schools Logo" src={logo} /> 
                <StudentTopText/>
            </div>
            <div id="overallbuttons">
                <LogoutButton />
            </div>
        </div>
    );
}