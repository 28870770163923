import React from 'react';
import Modal from 'react-modal';
import ReqRow from './ReqRow';
import { useState } from 'react';
import { useQuery } from '@apollo/client';
import { GET_REQUEST_INFO } from '../graphql/requests';

export default function TestModal(props) {
  const [studentName, setName] = useState('');
  const { loading, data, refetch } = useQuery(GET_REQUEST_INFO, {
    variables: { fullName: '', first: 20 }
  });
  if (loading) return 'Loading';
  const students = data.allUsers.edges;
  const categories = data.allCategories.edges;
  const user = data.viewer;
  const rosters = data.viewer.rosterSet.edges;
  const roster = rosters.length !== 0 ? rosters[0].node : '';
  refetch({ fullName: studentName });

  function submitRequest(reqName) {
    refetch({ fullName: studentName });
  }

  function handleChange(event) {
    setName(event.target.value);
  }

  function handleClose() {
    refetch({ fullName: '' });
    props.onRequestClose();
  }

  let requestContent = (
    <div>
      {' '}
      <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br />{' '}
    </div>
  );

  requestContent = (
    <div className="student-request-results">
      <table>
        {students.map(student => (
          <ReqRow
            key={student.node.id}
            student={student.node}
            categories={categories}
            user={user}
            roster={roster}
          />
        ))}
      </table>
    </div>
  );

  return (
    <Modal
      isOpen={props.isOpen}
      onRequestClose={handleClose}
      contentLabel="Request Modal"
      id="request-modal"
    >
      <h2>Request a Student</h2>
      <div>
        <label htmlFor="nameInput">Student Name: </label>
        <input id="nameInput" type="text" onChange={handleChange}></input>
        <button onClick={() => submitRequest({ studentName })}>Submit</button>
      </div>
      {requestContent}
      <button onClick={handleClose}>Close</button>
    </Modal>
  );
}
