import { AuthContext } from '../context/authContext.js';
import { useContext } from 'react';

export default function LogoutButton() {
  const { logout } = useContext(AuthContext);
  return (
    <div id="logout">
      <button onClick={logout}>Logout</button>
    </div>
  );
}
