import { RequestRow } from './RequestRowContent';

export default function RequestRows(props) {
  const requests = props.data.viewer.myRequests.edges;

  return (
    <>
      {requests.map(item => (
        <RequestRow key={item.node.id} request={item.node} />
      ))}
    </>
  );
}
