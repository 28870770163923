import { DateTime } from "luxon";
import toast from 'react-hot-toast';
import React, { useState } from 'react';
import {useQuery} from '@apollo/client';
import { useMutation, useLazyQuery } from '@apollo/client';
import { CREATE_REQUEST } from "../../graphql/requests.js";
import { GET_STUDENT_PORTAL_INFO } from '../../graphql/getCategories.js';
import "react-datepicker/dist/react-datepicker.css";
import { GET_REQUESTS_FOR_STUDENT } from "../../graphql/requestsforstudents.js";

export default function RequestTeacher() {
    const min_date = DateTime.now().toISO().split('T')[0];
    const [selectedTeacher, setTeacher] = useState(null);
    const [selectedReason, setSelectedReason] = useState(null);
    const [date, setDate] = useState(min_date);
    const { data, loading, error } = useQuery(GET_STUDENT_PORTAL_INFO);
    const toast_errors = error => toast.error(error.message);
    const [createRequest, { error: createReqErr, data: createReqData }] = 
    useMutation(CREATE_REQUEST, {
        refetchQueries: [GET_REQUESTS_FOR_STUDENT, 'getRequestsForStudent'],
        errorPolicy: 'none',
        awaitRefetchQueries: true
    });

    if(loading){
        return;
    }

    const viewer = data.viewer;

    const handleReasonChange = (event) => {
        setSelectedReason(event.target.value);
    };

    const handleTeacherChange = (event) => {
        setTeacher(event.target.value);
    };
  
    function requestTeacher()
    {
      if(selectedTeacher === null || selectedReason === null){
        return;
      }
      toast.loading('Creating request...');
      createRequest({
        variables: {
          studentId: viewer.id,
          requestorId: viewer.id,
          destinationId: selectedTeacher,
          categoryId: selectedReason,
          dateRequested: date
        }
      }) || toast.error();
      toast.remove();
      if (createReqErr) {
        createReqErr.graphQLErrors.map(toast_errors);
        //createReqErr.networkError.map(toast_errors);
      } else if (createReqData) {
        toast.success('Created request');
      }
    }

    return(
        <table className="roster">
            <tr id="roster-head">
            <th colSpan={4}>
                <div id="top-roster">
                    <div id="top-roster-title">Request Teacher for PASS</div>
                </div>
            </th>
            </tr>
            <tr>
            <th>Teacher</th>
            <th>Date</th>
            <th>Reason</th>
            <th>Empty</th>
            </tr>
            <tr>
                <td>
                    <select value={selectedTeacher} onChange={handleTeacherChange}>
                        <option value={null}>Select Teacher</option>
                        {data.allUsers.edges.map((item) =>
                        <option value={item.node.rosterSet.edges[0].node.id}>{item.node.firstName} {item.node.lastName}</option>
                        )}
                    </select>
                </td>
                <td>
                    <div>
	    		<input onChange={(e) => setDate(e.target.value)} type="date" min={min_date} value={date} required />
                    </div>
                </td>
                <td>
                    <select value={selectedReason} onChange={handleReasonChange}>
                        <option value={null}>Select Reason</option>
                        {data.allCategories.edges.map((item) =>
                        <option value={item.node.id}>{item.node.name}</option>
                        )}
                    </select>
                </td>
                <td>
                    <div id="sendrequestbutton">
                        <button onClick={requestTeacher}>Send Request</button>
                    </div>    
                </td>
            </tr>
        </table>
    );
}   
