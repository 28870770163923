import OverflowCheck from '../OverflowCheck';
import AbsenceCheck from './AbsenceCheckbox';
import getColorStatus from '../status';

export function RosterRow(props) {
  function getName(props) {
    return `${props.student.firstName} ${props.student.lastName}`;
  }

  function getRequestorDetails(props) {
    const requests = props.student.requestSet.edges;
    if (requests.length === 0) return null;
    return requests[0].node.requestor;
  }

  function getStatus(props) {
    const requests = props.student.requestSet.edges;
    if (requests.length === 0) return null;
    return requests[0].node.status[0];
  }

  const status = getStatus(props);
  const requestor = getRequestorDetails(props);

  return (
    <tr key={'tr' + props.student.id}>
      <td>{getName(props)}</td>
      <td>{requestor && getColorStatus(status)}</td>
      <td>{requestor && `${requestor.firstName} ${requestor.lastName}`}</td>
      <td>
        <AbsenceCheck student={props.student} />
      </td>
      <td>
        <OverflowCheck />
      </td>
    </tr>
  );
}
