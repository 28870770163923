import { gql } from '@apollo/client';

export const GET_REQUEST_INFO = gql`
  query getCategories($fullName: String, $first: Int) {
    allCategories {
      edges {
        node {
          name
          id
        }
      }
    }
    allUsers(group: "R3JvdXBOb2RlOjQ=", fullName: $fullName, first: $first) {
      edges {
        node {
          firstName
          lastName
          id
        }
      }
    }
    viewer {
      id
      rosterSet {
        edges {
          node {
            id
          }
        }
      }
    }
  }
`;

export const CREATE_REQUEST = gql`
  mutation CreateRequest(
    $studentId: ID!
    $requestorId: ID
    $destinationId: ID
    $categoryId: ID!
    $dateRequested: Date!
  ) {
    createRequest(
      input: {
        studentId: $studentId
        requestorId: $requestorId
        destinationId: $destinationId
        categoryId: $categoryId
        dateRequested: $dateRequested
      }
    ) {
      request {
        createdAt
        id
      }
    }
  }
`;

export const DELETE_REQUEST = gql`
  mutation DeleteRequest(
    $requestId: ID!
    $soft: Boolean!
  ) {
    deleteRequest(
      input: {
        requestId: $requestId
        soft: $soft
      }
    ) {
      request {
        status
      }
    }
  }
`;

export const CHECK_EXISTS = gql`
  query checkExists($dateRequested: Date!, $studentID: ID!) {
    allRequests(dateRequested: $dateRequested, student: $studentID) {
      edges {
        node {
          destination {
            id
          }
          student {
            id
          }
          dateRequested
        }
      }
    }
  }
`;


export const APPROVE_REQUEST = gql`
  mutation change_request($requestId: ID!) {
    changeRequest(input: {requestId: $requestId, approved: true}) {
      request {
        id
        status
      }
    }
  }
`;

export const DENY_REQUEST = gql`
  mutation change_request($requestId: ID!) {
    changeRequest(input: {requestId: $requestId, approved: false}) {
      request {
        id
        status
      }
    }
  }
`;
