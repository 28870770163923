export default function ForgotPasswordButton() {
  function handleLostPassword() {
    console.log("Hang on, I'm looking for it!");
  }
  return (
    <div id="lostPassword">
      <button id="forgotPassword" onClick={handleLostPassword}>
        Forgot password?
      </button>
    </div>
  );
}
