import './App.css';
import Login from './Login.js';
import { Routes, Route, Navigate } from 'react-router-dom';
import ThisWeek from './components/ThisWeek.jsx';
import NextWeek from './components/NextWeek.jsx';
import Roster from './components/roster/Roster.jsx';
import { AuthContext } from './context/authContext.js';
import { useContext } from 'react';
import StudentPortal from './components/studentportal/StudentPortal.jsx';

function App() {
  const { user } = useContext(AuthContext);
  const student = user ? user.groups.some(e => e.name === "Students") : false
  const faculty = user ? user.groups.some(e => e.name === "Faculty") : false

  return (
    <Routes>
      <Route path="/" element={<Navigate to={((faculty && '/roster') || (student && '/studentportal')) || '/login'} />} />
      <Route path="/login" element={<Login />} />
      <Route
        path="/thisweek"
        element={(faculty ? <ThisWeek /> : <Navigate to="/"/>)}
      />
      <Route
        path="/nextweek"
        element={(faculty ? <NextWeek /> : <Navigate to="/"/>)}
      />
      <Route
        path="/roster"
        element={faculty ? <Roster /> : <Navigate to="/"/>}
      />
      <Route
        path="/studentportal"
        element={student ? <StudentPortal /> : <Navigate to="/"/>}
      />
    </Routes>
  );
}

export default App;
