

export default function getColorStatus(reqStatus) {
    switch (reqStatus) {
        case "PRC": 
            return <span id="PRC-status">Processed</span>;
            break; 
        
        case "CNC": 
            return <span id="CNC-status">Canceled</span>;
            break; 

        case "DNY": 
            return <span id="CNC-status">Denied</span>;
            break; 

        case "ODT": 
            return <span id="overridden-status">Overridden</span>;
            break; 

        case "OPC": 
            return <span id="overridden-status">Overridden</span>;
            break;
            
        case "OPR": 
            return <span id="overridden-status">Overridden</span>;
            break;

        case "OPS": 
            return <span id="overridden-status">Overridden</span>;
            break;

        case "PND": 
            return <span id="PND-status">Pending</span>;
            break;

        case "ABS": 
            return <span id="CNC-status">Absent</span>;
            break;
        
        default: 
            return <span id="bold">{reqStatus}</span>;
    }
};